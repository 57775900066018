import React from 'react';

export class AnimatedButtons extends React.Component {
    render() {
        const { buttons } = this.props;

        return (
            <div className="button-container">
                {buttons.map((button, index) => (
                    <div key={`button-${index}`} className="button-item">
                        {button}
                    </div>
                ))}
            </div>
        );
    }
}

export default AnimatedButtons;

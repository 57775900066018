import React, { useState, useRef } from 'react';
import { Card, Space, Modal, Input, Button, message } from 'antd';
import { PushpinFilled } from '@ant-design/icons';
import './Center.less';
import MapModal from './MapModal/MapModal';

export const Center = (props) => {
  const { name, position, img, phone, longitude, latitude } = props;
  const { Meta } = Card;

  const [isMapModalVisible, setIsMapModalVisible] = useState(false);
  const [isPhoneModalVisible, setIsPhoneModalVisible] = useState(false);
  const phoneInputRef = useRef(null);

  const showMapModal = () => {
    setIsMapModalVisible(true);
  };

  const hideMapModal = () => {
    setIsMapModalVisible(false);
  };

  const showPhoneModal = () => {
    setIsPhoneModalVisible(true);
  };

  const hidePhoneModal = () => {
    setIsPhoneModalVisible(false);
  };

  const handleCopyPhone = () => {
    if (phoneInputRef.current) {
      phoneInputRef.current.select();
      document.execCommand('copy');
      message.success('Phone number copied to clipboard');
    }
  };

  return (
      <Card
          hoverable
          className="member"
          cover={<img alt={'name'} src={img} />}
      >
        <Space className="member__contact">
          <a
              className="member__contact-link"
              onClick={showMapModal}
              style={{ cursor: 'pointer' }}
          >
            <PushpinFilled />
          </a>
        </Space>
        <Meta title={name} description={position} />
        <Meta description={phone} />

        <Modal
            title={name}
            visible={isMapModalVisible}
            onCancel={hideMapModal}
            footer={null}
            style={{ top: 20 }}
        >
          <MapModal longitude={longitude} latitude={latitude} />
        </Modal>

        <Modal
            title="Phone Number"
            visible={isPhoneModalVisible}
            onCancel={hidePhoneModal}
            footer={null}
        >
          <Input
              ref={phoneInputRef}
              value={phone}
              readOnly
              addonAfter={
                <Button onClick={handleCopyPhone}>Copy</Button>
              }
          />
        </Modal>
      </Card>
  );
};

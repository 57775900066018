import React from 'react';
import "./SectionHeading.less";
import AnimatedText from "../TextAnimationComponents/AnimatedText";
import AnimatedParagraph from "../TextAnimationComponents/AnimatedParagraph";

export const SectionHeading = (props) => {
    const { heading, subHeading, className = "" } = props;

    const mainClassName = `section-heading__main ${className === 'nehemiah' ? 'white-text' : ''}`;
    const subClassName = `section-heading__sub ${className === 'nehemiah' ? 'white-text' : ''}`;

    console.log(className)

    return (
        <div className={`section-heading ${className}`}>
            <AnimatedText text={heading} className={mainClassName}/>
            <AnimatedParagraph text={subHeading} className={subClassName} />
        </div>
    )
}

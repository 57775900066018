import React from "react";
import "./AnimatedParagraph.css"; // Create a CSS file for animations

class AnimatedParagraph extends React.Component {
    render() {
        const { text, className } = this.props;
        return (
            <p
                className={`animated-paragraph intro__sub-heading ${className}`}
                dangerouslySetInnerHTML={{ __html: text }}
            />
        );
    }
}

export default AnimatedParagraph;

import React, {useEffect, useState} from 'react';
import {Footer} from 'antd/lib/layout/layout';
import {Col, Divider, Row} from 'antd';
import {ArrowUpOutlined, EnvironmentOutlined, MailOutlined, PhoneOutlined} from '@ant-design/icons';
import {Container} from "../Container/Container";

import "./AppFooter.css";
import "./AppFooter.less";
import "../../styles/main.less";

export const AppFooter = () => {

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("down");

  useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollTop > 100) {
        setShowScrollToTop(true);
        setScrollDirection("up");
      } else {
        setShowScrollToTop(false);
        setScrollDirection("down");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToBottom = () => {
    const element = document.documentElement || document.body;
    const maxScrollTop = element.scrollHeight - element.clientHeight;

    window.scrollTo({
      top: maxScrollTop,
      behavior: 'smooth',
    });
  };


  window.onscroll = () => {
    if (document.documentElement.scrollTop > 100) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };

  function changeTextColorsToWhiteAndBold() {
    const appFooter = document.querySelector('.app-footer');

    if (appFooter) {
      const elementsToChangeColor = appFooter.querySelectorAll('h1, h3, p');

      elementsToChangeColor.forEach(element => {
        element.style.color = 'white';
        element.style.fontWeight = '700';
        element.style.fontSize = '18px';
      });
    }
  }

  changeTextColorsToWhiteAndBold();


  return (
      <Footer className="app-footer" style={{
        background: 'linear-gradient(to right,#33965A 0%,#33965A 50%,#33965A 100%)',
        color: '#fff',
        padding: '40px 0',
      }}>
        <Container className="app-footer__content">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="footer-column">
                <EnvironmentOutlined />
                <h3 style={{ color:"white" }}>ADDRESS</h3>
                <p>P/Bag Mbungo</p>
                <p>Masvingo, Zimbabwe</p>
                <p><a href="/contact">More...</a></p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="footer-column">
                <PhoneOutlined />
                <h3>PRAYER LINES</h3>
                <p><a href="tel:+2638688006187" style={{color: 'white'}}>+263 8688 006 187</a></p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="footer-column">
                <MailOutlined />
                <h3>EMAIL</h3>
                <p><a href="mailto:info@zccmbungo.org" style={{ color: 'white'}}>info@zccmbungo.org</a></p>
              </div>
            </Col>
          </Row>
          <Divider style={{backgroundColor: 'white'}}/>
          <div>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="copyright">
                  <p style={{ color:'white', fontSize: '18px'}}
                  >&copy; ZCC {currentYear}</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="social-links">
                  <a href="https://facebook.com/ZCCMbungo" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href="https://twitter.com/ZCC_Mbungo" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter" style={{ color: 'white' }} onMouseEnter={(e) => e.target.style.color = '#00acee'} onMouseLeave={(e) => e.target.style.color = 'white'}></i>
                  </a>
                  <a href="https://www.youtube.com/channel/UCb6jT0K8n9S3KPL1pQu-OiQ" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube" style={{ color: 'white' }} onMouseEnter={(e) => e.target.style.color = 'red'} onMouseLeave={(e) => e.target.style.color = 'white'}></i>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          {showScrollToTop && (
              <div className="scroll-to-top" onClick={scrollToTop} style={{ transform: `rotate(${scrollDirection === "up" ? "360deg" : "180"})` }}>
                <ArrowUpOutlined />
              </div>
          )}
          {!showScrollToTop && (
              <div className="scroll-to-top"
                   onClick={scrollToBottom}
                   style={{ transform: `rotate(${scrollDirection === "down" ? "180deg" : "0"})` }}
              >
                <ArrowUpOutlined />
              </div>
          )}
        </Container>
      </Footer>
  )
}

import React from "react";
import TweenOne from 'rc-tween-one';

class AnimatedText extends React.Component {
    getEnter = (e) => {
        return {
            opacity: 0,
            scale: 0.8,
            y: '-100%',
        };
    }

    render() {
        const { text, className } = this.props;
        return (
            <h1 className={`animated-heading intro__heading ${className}`}>
                {/*<TweenOne*/}
                {/*    type="mask-top"*/}
                {/*    delay={400}*/}
                {/*    enter={this.getEnter}*/}
                {/*    animation={[*/}
                {/*        {*/}
                {/*            letterSpacing: 0,*/}
                {/*            delay: -300,*/}
                {/*            scale: 0.9,*/}
                {/*            ease: 'easeInOutQuint',*/}
                {/*            duration: 1000,*/}
                {/*        },*/}
                {/*        { scale: 1, width: '100%', delay: -300, duration: 1000, ease: 'easeInOutQuint' },*/}
                {/*    ]}*/}
                {/*>*/}
                    {text}
                {/*</TweenOne>*/}
            </h1>
        );
    }
}

export default AnimatedText;

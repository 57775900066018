import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Row, Card, Modal } from 'antd';
import { SectionHeading } from '../../components/SectionHeading/SectionHeading';
import { Container } from '../../components/Container/Container';
import { CalendarOutlined, UserOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import axios from 'axios';
import './Channel.less';

export const Channel = ({ onThumbnailClick }) => {
  const { Meta } = Card;
  const [videoList, setVideoList] = useState([]);
  const [nextPageToken, setNextPageToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
  const [visible, setVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [hasLivestream, setHasLivestream] = useState(false);
  const [showUpcomingEvent, setShowUpcomingEvent] = useState(false);

  const containerRef = useRef(null);

  const apiKey = 'AIzaSyDYCQyJbR_vVLrbDJ-3wisXmadqfGKeM9A';
  const channelId = 'UCb6jT0K8n9S3KPL1pQu-OiQ';

  useEffect(() => {
    fetchVideoData();
  }, []);

  const fetchVideoData = async (pageToken = '') => {
    setLoading(true);
    try {
      const response = await fetch(
          `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&maxResults=6&pageToken=${pageToken}`
      );
      const data = await response.json();

      console.log('Data is here', data);
      if (data.items && data.items.length > 0) {
        const updatedVideoList = [...videoList, ...data.items];
        setVideoList(updatedVideoList);
        setNextPageToken(data.nextPageToken);

        const upcomingEvents = updatedVideoList.filter(
            (video) => video.snippet.liveBroadcastContent === 'upcoming'
        );

        if (upcomingEvents.length > 0) {
          const earliestEvent = upcomingEvents.reduce((earliest, current) => {
            const earliestTime = new Date(earliest.snippet.publishedAt);
            const currentTime = new Date(current.snippet.publishedAt);
            return earliestTime < currentTime ? earliest : current;
          });

          setShowUpcomingEvent(true);
          setVideoUrl(`https://www.youtube.com/watch?v=${earliestEvent.id.videoId}`);
          setVisible(true);
        }
      } else {
        console.error('No video data found.');
      }
    } catch (error) {
      console.error('Error fetching video data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    fetchVideoData(nextPageToken);
  };

  const showVideo = (videoId) => {
    setVideoUrl(`https://www.youtube.com/watch?v=${videoId}`);
    setVisible(true);
    setShowUpcomingEvent(false);

    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const closeModal = () => {
    setVisible(false);
    setShowUpcomingEvent(false);
  };

  const modalStyle = {
    width: '800px',
    height: '450px',
  };

  const renderVideoContent = () => {
    if (showUpcomingEvent) {
      const videoResolution = {
        width: 896,
        height: 504,
      };

      return (
          <div
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
              ref={containerRef}
          >
            <ReactPlayer
                url={videoUrl}
                controls
                width={`${videoResolution.width}px`}
                height={`${videoResolution.height}px`}
            />
          </div>
      );
    }

    else {
      const videoResolution = {
        width: 1152,
        height: 648,
      };

      return (
          <div
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
              ref={containerRef}
          >
            <ReactPlayer
                url={videoUrl}
                controls
                width={`${videoResolution.width}px`}
                height={`${videoResolution.height}px`}
            />
          </div>
      );
    }
    return null;
  };

  return (
      <section id="media" className="showcase">
        <Container fluid className="showcase__container">
          <SectionHeading className="showcase__heading" heading="Most Recent Videos" />

          {visible && (
              <div className="video-player-container" onClick={closeModal}>
                {renderVideoContent()}
              </div>
          )}

          <Row gutter={[24, 24]} justify="center">
            {videoList.map((video, index) => (
                <Col xs={20} md={12} lg={8} key={video.id}>
                  <a onClick={() => showVideo(video.id.videoId)}>
                    <Card
                        className="post"
                        hoverable
                        cover={<img alt={video.snippet.title} src={video.snippet.thumbnails.medium.url} />}
                        actions={[
                          <div className="post__info">
                            <div>
                              <UserOutlined />
                              <span> Posted by ZCC Media</span>
                            </div>
                            <div>
                              <CalendarOutlined />
                              <span> {video.snippet.publishedAt}</span>
                            </div>
                          </div>,
                        ]}
                    >
                      <Meta title={video.snippet.title} description={video.snippet.title} />
                    </Card>
                  </a>
                </Col>
            ))}
          </Row>
          {nextPageToken && (
              <div className="load-more-button">
                <Button type="primary" loading={loading} onClick={handleLoadMore}>
                  Load More
                </Button>
              </div>
          )}
        </Container>
      </section>
  );
};

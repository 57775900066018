import React from 'react';
import { Col, Row } from 'antd';
import { Container } from '../../components/Container/Container';
import { AppButton } from '../../components/AppButton/AppButton';
import { SectionHeading } from '../../components/SectionHeading/SectionHeading';
import { images } from '../../constants/imageData';
import Fade from 'react-reveal/Fade'; // Import the Fade component
import './VasheSamuel.less';

export const VasheSamere = () => {
    return (
        <Fade bottom> {/* Apply the Fade animation to the entire section */}
            <section id="BusinessPlan" className="business-plan">
                <SectionHeading heading="Reverend Samuel Mutendi" subHeading="Our Founder" style={{ color: '#1c341f' }} className={'samere'} />
                <Container className="business-plan__container">
                    <Row gutter={64}>
                        <Col sm={24} md={12}>
                            <img className="business-plan__img" src={images.vasheSamuel} alt={"Vashe Samere"} />
                        </Col>
                        <Col sm={24} md={12}>
                            <p style={{ color: '#1c341f', fontSize: '1.2rem' }} className="business-plan__desc">
                                2 Timothy 4
                                "7 I have fought a good fight, I have finished my course, I have kept the faith.
                                8 Henceforth there is laid up for me a crown of righteousness, which the Lord, the righteous judge, shall give me at that day: and not to me only, but unto all them also that love his appearing."
                            </p>
                            <a
                                href="/history"
                                rel="noreferrer"
                            >
                                <AppButton type="primary" >
                                    Our History
                                </AppButton>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fade>
    );
};

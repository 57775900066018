import {Tabs} from 'antd';
import React, {useState, useEffect} from 'react';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
const {TabPane} = Tabs;

const EventsPage = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetch(`${baseApiUrl}events/all`)
            .then((response) => response.json())
            .then((data) => {
                setEvents(data.content);
            })
            .catch((error) => {
                console.error('Error fetching events:', error);
            });
    }, []);

    const customTabStyles = {
        fontWeight: 'bold',
    };

    const tabBarStyle = {
        color: '#000',
    };

    const activeTabBarStyle = {
        color: 'green',
    };

    return (
        <section className="ftco-section bg-light">
            <div className="container">
                <div className="row justify-content-center mb-5 pb-3">
                    <div className="col-md-7 text-center heading-section ftco-animate">
                        {/*<span className="subheading">Schedule</span>*/}
                        <h2 className="mb-4">
                            <span>Event</span> Schedule
                        </h2>
                    </div>
                </div>
                <div className="ftco-search">
                    <div className="row">
                        <div className="col-md-12 nav-link-wrap">
                            <Tabs defaultActiveKey="1" tabPosition="left" defaultActiveKey="1"
                                  tabPosition="left"
                                  tabBarStyle={tabBarStyle}
                                  activeTabBarStyle={activeTabBarStyle}>
                                {events.map((event, index) => (
                                    <TabPane
                                        tab={<p style={customTabStyles}>{event.E}</p>}
                                        key={index + 1}
                                    >
                                        <div className="speaker-wrap ftco-animate d-flex">
                                            <div
                                                className="img speaker-img"
                                                style={{
                                                    backgroundImage: `url(${baseApiUrl}${event.U})`,
                                                }}
                                            ></div>
                                            <div className="text pl-md-5">
                        <span className="time">
                          {`${event.V} - ${event.N}`}
                        </span>
                                                <h2>
                                                    <a href="#">{event.E}</a>
                                                </h2>
                                                <p>Description</p>
                                                <h3 className="speaker-name">
                                                    <a href="#">Location</a>{' '}
                                                    &mdash;
                                                    <span className="position">
                            {' '}Zion Christian Church
                          </span>
                                                </h3>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EventsPage;

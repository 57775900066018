import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '../../../components/Container/Container';
import './Article.less';

const Article = () => {
    const { slug } = useParams();
    const [articleData, setArticleData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`/news/api/posts/${slug}`)
            .then((response) => response.json())
            .then((data) => {
                const modifiedBody = data.body.replace(
                    /src="([^"]*)/g,
                    'src="https://zccmbungo.org$1'
                );

                setArticleData({ ...data, body: modifiedBody });
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching article data:', error);
                setLoading(false);
            });
    }, [slug]);

    return (
        <Container>
            <div className="center-content">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <div>
                        <h2>{articleData.title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: articleData.body }} />
                    </div>
                )}
            </div>
        </Container>
    );
};

export default Article;

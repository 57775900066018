import React from 'react';
import TweenOne from 'rc-tween-one';

const AnimatedImage = ({ classNa, src, alt }) => {
    const animationProps = {
        opacity: 1,
        scale: 1,
        duration: 600,
        ease: 'easeInOutQuart',
    };

    return (
        <TweenOne animation={animationProps}>
            <img
                className={classNa}
                src={src}
                alt={alt}
            />
        </TweenOne>
    );
};

export default AnimatedImage;

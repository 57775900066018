import React from 'react';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const Mapbox = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoidGF0ZW5kYXoiLCJhIjoiY2tnNG90MWc1MG1qbTJwbXplaTQ3NXMwYyJ9.j7qqPzCTk0SXlBAsPgPkVA',
});

const MapModal = ({ visible, onCancel, longitude, latitude}) => {
    return (

            <div style={{ width: '100%', height: '100vh' }}>
                <Mapbox
                    style="mapbox://styles/mapbox/streets-v11"
                    containerStyle={{
                        height: '100%',
                        width: '100%',
                    }}
                    center={[longitude, latitude]}
                    zoom={[150]}
                >
                    <Marker coordinates={[longitude, latitude]}>
                        <div style={{ width: '30px', height: '30px', backgroundColor: 'red', borderRadius: '50%' }}></div>
                    </Marker>
                </Mapbox>
            </div>
    );
};

export default MapModal;

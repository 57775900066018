import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Fade from "react-reveal/Fade";
import AnimatedButtons from "../../../components/ButtonsAnimation/AnimatedButtons";
import { AppButton } from "../../../components/AppButton/AppButton";
import { Space } from "antd";

import './Events.less'

const baseapiurl = process.env.REACT_APP_BASE_API_URL;

export const Events = () => {
    const [eventData, setEventData] = useState(null);

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const response = await axios.get(`${baseapiurl}events/all`);
                setEventData(response.data.content[0]);
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchEventData();
    }, []);

    return (
        <>
            <div className="hero-wrap js-fullheight d-flex justify-content-center align-items-center position-relative">
                {eventData && eventData.U ? (
                    <>
                        <img
                            src={`https://next.zccmbungo.org/zcc-website-api/api${eventData.U}`}
                            alt="Event"
                            className="full-width-image"
                            style={{ maxHeight: '100vh', width: 'auto', objectFit: 'contain' }}
                        />
                    </>
                ) : (
                    <>
                        <img
                            src={`${baseapiurl}events/images/downloadFile/21-11-2023132807IMG-20231119-WA0021.jpg`}
                            alt="Default Event"
                            className="full-width-image"
                            style={{ maxHeight: '100vh', width: 'auto', objectFit: 'contain' }}
                        />
                    </>
                )}
                <div className="container text-center position-absolute bottom-0 start-50 translate-middle-x mb-4">
                    <Space size={"large"} className="intro__button-group">
                        <Fade left>
                            <AnimatedButtons buttons={[
                                <AppButton type="primary">
                                    <a
                                        rel="noreferrer"
                                        href="/events"
                                        style={{ color: 'white' }} // Change text color if needed
                                    >
                                        See More Events
                                    </a>
                                </AppButton>
                            ]} />
                        </Fade>
                    </Space>
                </div>
            </div>
        </>
    );
};

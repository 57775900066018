import React, { useEffect, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { Container } from '../../components/Container/Container';
import { SectionHeading } from '../../components/SectionHeading/SectionHeading';
import { Post } from './Post/Post';
import "./News.less";
import Jump from "react-reveal/Jump";

const newsBaseAPIUrl = process.env.REACT_APP_BASE_API_URL_NEWS;

export const News = () => {
  const [postList, setPostList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${newsBaseAPIUrl}news/api/posts`)
        .then(response => response.json())
        .then(data => {
          setPostList(data.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
  }, []);

  return (
      <section id="news" className="blog">
        <Container>
            <Jump>
                <SectionHeading heading="Latest News" />
            </Jump>
          <Divider style={{ backgroundColor: '#33965A', height: '4px' }} dashed={true} />
          {loading ? (
              <p>Loading...</p>
          ) : (
              <Row gutter={[24, 24]} justify="center">
                {postList.map((post, index) => (
                    <Col xs={20} md={12} lg={8} key={post.id}>
                        <Post
                            title={post.title}
                            description={post.title}
                            timeRead={post.read_time}
                            author={post.user.name}
                            publishedTime={post.published_at}
                            alt={post.title}
                            url={`/article/${post.slug}`}
                            showCoverImage={false}
                        />
                    </Col>
                ))}
              </Row>
          )}
        </Container>
      </section>
  );
}

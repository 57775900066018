import React, {useEffect} from 'react';
import {Col, Divider, Image, Row} from 'antd';
import {Container} from "../../components/Container/Container";
import {AboutCard} from './AboutCard/AboutCard';
import {SectionHeading} from '../../components/SectionHeading/SectionHeading';
import {images} from '../../constants/imageData';
import "./About.less"
import Slide from "react-reveal/Slide";
import Jump from "react-reveal/Jump";

export const About = () => {
  const description = 'It was established when Reverend Samuel Mutendi received spiritual baptism in 1913. Rev. Samuel Mutendi (1880 – 1976) was born and grew up in the province of Fort Victoria (now Masvingo) when the country was still Southern Rhodesia under British colonial rule. Samuel Mutendi was visited by the Holy Spirit in 1913 whilst working as a policeman for the British South African Police (BSAP) in what was then called Hartley (now Chegutu). His selfless dedication to Christian mission, his powerful preaching of the Word of God amongst the African people and his amazing gift of spiritual healing have been chronicled since colonial Rhodesia. After 63 years of Christian ministry preaching around the country Samuel Mutendi died in 1976 and his eventful end and promotion to glory has been the subject of dialogue and testimony for the past four decades.'
  const description2 = 'His son Nehemiah Mutendi (born 1939) was consecrated as Bishop in 1978 and has led this dynamic church for the past 45 years. He carries on the mission of his late father and at the helm has overseen the rapid growth of the church in urban centers of the country, and infused it with a global vision as seen in the establishment of parishes in neighboring countries and many other countries around the world [link to Contact page for all parishes here]. With its foundation rooted in the infallible Word of God and the primacy of biblical law, as manifested in the exemplary life of Jesus Christ, ZCC continues to set the standard for excellence in African Christian ministry. This is seen in the mark made in the lives of thousands of once hopeless individuals and families who were ensnared by illness, poverty and ignorance but have received a new lease of life through the church.'

  useEffect(() => {
    const applyStyles = () => {

      const subHeader = document.querySelectorAll('.section-heading  p');

      subHeader.forEach((element) => {
        element.style.color = '#1c341f';
      });

      const divElements = document.querySelectorAll('.home div');

      divElements.forEach((element) => {
      });

      const h1Elements = document.querySelectorAll('.home h1');

      h1Elements.forEach((element) => {

      });

      const pElements = document.querySelectorAll('.home p');

      pElements.forEach((element) => {
      });
    };

    applyStyles();
  }, []);


  return (
    <section id="about" className="about">
      <Container>
        <Jump>
          <SectionHeading className={'about'} heading='About' subHeading='Zion Christian Church (ZCC) is one of the oldest independent African churches in Zimbabwe' />
        </Jump>
        <Divider style={{backgroundColor: '#33965A',  height: '4px'}} dashed={true} />
        <Row gutter={[24, 24]} justify="center">
          <Col sm={24} md={24} lg={12}>
            <Slide left>
              <AboutCard
                  description={description}
              />
            </Slide>
          </Col>
          <Col sm={24} md={24} lg={12}>
            <Slide right>
              <Image
                  src={images.mutarara}
              />
            </Slide>
          </Col>
        </Row>
        <Divider style={{backgroundColor: '#33965A',  height: '4px'}} dashed={true} />
        <Row gutter={[24, 24]} justify="center">
          <Col sm={24} md={24} lg={12}>
            <Slide left>
              <Image
                  src={images.onPodium}
              />
            </Slide>
          </Col>
          <Col sm={24} md={24} lg={12}>
            <Slide right>
              <AboutCard
                  description={description2}
              />
            </Slide>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

import {Timeline} from 'antd';

export const Milestones = () =>(
    <div className="timeline_container">
  <Timeline mode={'left'}>
    <br />
    <Timeline.Item label="1932">6 schools were built in Bikita, Gutu and Zaka.</Timeline.Item>
    <Timeline.Item label="1958">Mutarara Church and Office</Timeline.Item>
    <Timeline.Item label="1965">Mutarara Primary School</Timeline.Item>
    <Timeline.Item label="1967">Mutendi Primary School (Maramasimbe)</Timeline.Item>
    <Timeline.Item label="1973">Mutendi Primary School (Defe)</Timeline.Item>
    <Timeline.Item label="1981">Purchase of Mbungo Estates</Timeline.Item>
    <Timeline.Item label="1983">Mutendi Primary School (Mbungo)</Timeline.Item>
    <Timeline.Item label="1984">Mutendi High School (Mbungo)</Timeline.Item>
    <Timeline.Item label="1988">Dzidzai Adult Literacy program launched</Timeline.Item>
    <Timeline.Item label="1988">Hebron High School (Mwenezi)</Timeline.Item>
    <Timeline.Item label="1994">Dopota Primary</Timeline.Item>
    <Timeline.Item label="2000">Defe Secondary School</Timeline.Item>
    <Timeline.Item label="2001">Mutevhure Secondary School</Timeline.Item>
    <Timeline.Item label="2003">Fombo Secondary School</Timeline.Item>
    <Timeline.Item label="2004">Mutarara Primary School</Timeline.Item>
    <Timeline.Item label="2004">Gweru Church</Timeline.Item>
    <Timeline.Item label="2011">Mbungo House of God</Timeline.Item>
    <Timeline.Item label="2011">Mbungo Guest Lodge and Administration block</Timeline.Item>
    <Timeline.Item label="2011">Rehabilitation of the Defe feeder road (23 km)</Timeline.Item>
    <Timeline.Item label="2012">Mbungo Pavilion</Timeline.Item>
    <Timeline.Item label="2013">Defe Pavilion</Timeline.Item>
    <Timeline.Item label="2013">Electrification of Defe (23 km ZESA Powerline)</Timeline.Item>
    <Timeline.Item label="2013">Belvedere Administration block</Timeline.Item>
  </Timeline>
</div>
)
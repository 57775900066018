import React, { useEffect, useState } from 'react';
import "./SectionHeading.less";

export const BishopHeading = (props) => {
    const { heading, subHeading, className = "" } = props;
    const [h1Style, setH1Style] = useState({});
    const [pStyle, setPStyle] = useState({});

    useEffect(() => {
        // Define the CSS styles for the <h1> tag
        const updatedH1Style = {
            color: 'white', // Set the text color to white
            fontWeight: 'bold', // Add this line to set the font weight to bold for <h1>
        };

        // Define the CSS styles for the <p> tag
        const updatedPStyle = {
            color: 'white', // Set the text color to white for <p>
            // Add any other styles you want for <p> here
        };

        // Update the state with the new styles for <h1> and <p>
        setH1Style(updatedH1Style);
        setPStyle(updatedPStyle);
    }, []); // Empty dependency array to run the effect only once

    return (
        <div className={`section-heading ${className}`}>
            <h1 style={h1Style}>{heading}</h1>
            <p style={pStyle}>{subHeading}</p>
        </div>
    )
}

import React, {useEffect} from 'react';
import {Card, Col, Row, Image, Divider} from 'antd';
import {SectionHeading} from '../../components/SectionHeading/SectionHeading';
import {Container} from '../../components/Container/Container';

import "./Beliefs.less"
import {images} from "../../constants/imageData";
import Jump from "react-reveal/Jump";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";

export const Beliefs = () => {

   useEffect(() => {
    const applyStyles = () => {
      
      const pElements = document.querySelectorAll('.section-heading  p');

      pElements.forEach((element) => {
        element.style.color = '#1c341f';
      });
    };

    applyStyles();
  }, []);


  return (
    <section id="beliefs" className="pricing">
      <Container>
          <Jump>
              <SectionHeading className={'beliefs'} heading="Our Beliefs" subHeading='As its name suggests the Zion Christian Church equally holds to the teachings of the Old Testament (Zion),
the New Testament (Christian) and the Acts of the Apostles (Church).' />
          </Jump>
          <Divider style={{backgroundColor: '#33965A',  height: '4px'}} dashed={true} />
        <Row gutter={[24, 24]} justify="center">
            <Col xs={24} sm={24} md={24} lg={12} key="Zion">
                <Slide left>
                    <Card hoverable >
                        <h2 className="price-card__title">Zion</h2>
                        <p className="price-card__feature" key={"zion"}>Zion is a tradition established by God himself; a tradition of how to pray and praise God Almighty as taught by Old Testament prophets and their prophecies. Zion Christian Church believes that:</p>
                        <Fade top cascade>
                            <ol className="price-card__feature" justify="left">
                                <li>We praise and honour one God, the Almighty (Exodus 20: 3)</li>
                                <li>We keep all statutes and judgements (Deut. 4: 4-6)</li>
                                <li>Offerings are presented to God Almighty as we saw with Cain and Abel (Genesis 4: 2); Solomon (2 Chronicles 1:6, Psalms 50:5), and that The Lord is worshipped and praised through offerings (Psalms 50: 23; 1 Chronicles 16: 29).</li>
                                <li>Pledges should be made before God Almighty (Psalms 50: 14; Psalms 65: 1).</li>
                                <li>God’s kingdom should be built here on earth as we saw with King Solomon (2 Chronicles 2: 4; 2: 5), King David (2 Chronicles 29: 2-3) and with Nehemiah (Nehemiah 2: 5)</li>
                                <li>Tithes (10%) of all earnings should be presented to God Almighty as we saw with Abraham presenting his to the High Priest Melchizedek (Genesis 14: 17), with Jacob’s pledge (Genesis 28: 22); and as taught by Malachi (Malachi 3: 10).</li>
                                <li>The Lord’s House should be filled to the brim with offerings (2 Chronicles 31: 10; 29: 31; 2 Kings 4: 10).</li>
                                <li>Three times a year people should appear before The Lord their God (Deut. 16: 16; 5: 12) and shall not appear before Him empty-handed (Deut. 16: 16b and 16: 17).</li>
                            </ol>
                        </Fade>
                    </Card >
                </Slide>
              </Col>
            <Col className="columnImage-ZION" xs={24} sm={24} md={24} lg={12} key="Zion" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>

            </Col>
        </Row>
          <Row>
              <Col xs={24} sm={24} md={24} lg={12} key="Zion" className="columnImage-CHRISTIAN">

              </Col>
              <Col xs={24} sm={24} md={24} lg={12} key="Christian">
                  <Slide right>
                      <Card hoverable>
                          <h2 className="price-card__title">Christian</h2>
                          <p className="price-card__feature" key={"zion"}>Zion Christian Church also believes in the teachings of Jesus Christ as seen in the New Testament Books, from Matthew to John, where Christ declared Himself as, “the way, the truth, and the life” and where we saw Him commission His great mission to fight sickness and poverty, saying thus: “The Spirit of the Lord is upon me, because he hath anointed me to preach the gospel to the poor; he hath sent me to heal the brokenhearted, to preach deliverance to the captives, and recovering of sight to the blind, to set at liberty them that are bruised” (Luke 4: 18) And where he taught that:</p>
                          <Fade top cascade>
                              <ol className="price-card__feature">
                                  <li className="price-card__feature" >There is Heaven and Hell (Matthew 25)</li>
                                  <li className="price-card__feature" >Besides holding fast to statutes and judgements, what leads us to Heaven is to work for the Kingdom of God (Matthew 25: 32; Matthew 25: 1; Matthew 6:33)</li>
                                  <li className="price-card__feature" >Complete worshiping includes: generous giving (Matthew 6: 18), fasting (Matthew 6: 16) and serving the Heavenly kingdom (Matthew 6: 19)</li>
                                  <li className="price-card__feature" >God despises slothful people and we should work hard so as to be self-sufficient and so as to come before the Lord our God with gifts in our hands (Matthew 25: 26; 20: 6).</li>
                              </ol>
                          </Fade>
                      </Card>
                  </Slide>
              </Col>
          </Row>
          <Row>
              <Col xs={24} sm={24} md={24} lg={12} key="Church">
                  <Slide left>
                      <Card hoverable>
                          <h2 className="price-card__title">Church</h2>
                          <p className="price-card__feature" key={"zion"}>Zion Christian Church believes in and continues all the work and evangelism done in the name of Jesus Christ as begun in the Acts of the Apostles and other books chronicling God’s work after the death of Christ where it has been preached that He is, “the way, the truth, and the life.”
                              <br /><br />
                              We believe we are part of those:</p>
                          <Fade top cascade>
                              <ol className="price-card__feature">
                                  <li>Commissioned to preach the Gospel to the uttermost parts of the world (Acts 1: 8) so as to build the Kingdom of God on Earth</li>
                                  <li>Who organise themselves for the ministry of the word; to teach and preach Jesus Christ exhorting people to repent; for fighting demons and evil spirits and, for supporting the operation of the ministry</li>
                                  <li>Empowered by the Comforter, the Holy Spirit of Truth (John 14: 16) which gives us the power in the name of Jesus Christ our Saviour to:</li>
                                  <ol>
                                      <li>Be witnesses of Christ to the uttermost parts of the world</li>
                                      <li>Work for the Kingdom of Heaven</li>
                                      <li>Convert</li>
                                      <li>Prophesy (Acts 2: 17)</li>
                                  </ol>
                              </ol>
                          </Fade>
                      </Card>
                  </Slide>

                
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} key="Zion" className="columnImage-CHURCH">
              </Col>
        </Row>
      </Container>
    </section>
  )
}

import React, { Component } from 'react';
import { Col, Row, Divider, Space, Image } from 'antd';
import 'rc-texty/assets/index.css';
import "./Home.less";
import { Container } from '../../components/Container/Container';
import { AppButton } from '../../components/AppButton/AppButton';
import { VasheSamere } from '../Vashe/VasheSamere'
import { PriceCard } from '../Beliefs/PriceCard/PriceCard'
import { BabaNehemiah } from '../Vashe/BabaNehemiah';
import { images } from '../../constants/imageData';
import AnimatedText from "../../components/TextAnimationComponents/AnimatedText";
import AnimatedParagraph from "../../components/TextAnimationComponents/AnimatedParagraph";
import AnimatedButtons from "../../components/ButtonsAnimation/AnimatedButtons";
import AnimatedImage from "../../components/ImageAnimation/AnimatedImage";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Pulse from 'react-reveal/Pulse';
import {Events} from "./Events/Events";
export class Home extends Component {

  state = {
    show: true,
  }
  geInterval = (e) => {
    switch (e.index) {
      case 0:
        return 0;
      case 1:
        return 150;
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return 150 + 450 + (e.index - 2) * 10;
      default:
        return 150 + 450 + (e.index - 6) * 150;
    }
  }
  getEnter = (e) => {
    const t = {
      opacity: 0,
      scale: 0.8,
      y: '-100%',
    };
    if (e.index >= 2 && e.index <= 6) {
      return { ...t, y: '-30%', duration: 150 };
    }
    return t;
  }

  getSplit = (e) => {
    const t = e.split(' ');
    const c = [];
    t.forEach((str, i) => {
      c.push((
          <span key={`${str}-${i}`}>
          {str}
        </span>
      ));
      if (i < t.length - 1) {
        c.push(<span key={` -${i}`}> </span>);
      }
    });
    return c;
  }

  onClick = () => {
    this.setState({
      show: false,
    }, () => {
      this.setState({
        show: true
      });
    });
  }
  componentDidMount() {
    this.applyStyles();
  }

  applyStyles() {
    const subHeader = document.querySelectorAll('.section-heading  p');
    subHeader.forEach((element) => {
      element.style.color = '#1c341f';
    });

    const divElements = document.querySelectorAll('.home div');
    divElements.forEach((element) => {
      element.style.color = '#1c341f';
      element.style.fontSize = '2rem';
      element.style.textTransform = 'capitalize';
    });

    const h1Elements = document.querySelectorAll('.home h1');
    h1Elements.forEach((element) => {
      element.style.color = '#1c341f';
      element.style.fontSize = '2rem';
      element.style.textTransform = 'capitalize';
    });

    const pElements = document.querySelectorAll('.home p');
    pElements.forEach((element) => {
      element.style.textTransform = 'capitalize';
    });
  }

  render() {
    const ZCC = [
      {
        title: "Zion",
        price: "Isaiah 14",
        features: [
          "32 What shall one then answer the messengers of the nation? That the Lord has founded Zion, and the poor of His people shall find refuge in it.",
        ],
        buttonType: "default",
        image: images.bishopTrain
      },
      {
        title: "Christian",
        price: "Zechariah 9",
        features: [
          "9 Rejoice greatly, O daughter of Zion! And cry aloud, O daughter of Jerusalem! See, your king is coming to you; he is righteous and able to deliver, he is humble and riding on a donkey, a colt, the offspring of a donkey.",
        ],
        buttonType: "primary",
        image: images.nationalSports
      },
      {
        title: "Church",
        price: "John 1",
        features: [
          "1 In the beginning was the Word, and the Word was with God, and the Word was God. 12 Yet to all who received Him, He gave the power to become sons of God, to those who believed in His name, 13 who were born not of blood, nor of the will of the flesh, nor of the will of man, but of God.",
        ],
        buttonType: "default",
        image: images.brassBand
      },
    ];

    return (
        <section id="home" className="home">
          <Container className="home__container">
            <Events />
            <br />
            <Divider style={{ backgroundColor: '#33965A', height: '4px' }} dashed={true} />
            <br />
            <Row>
              <Row>
                <Col xs={24} md={12} className="home__intro">
                  <Fade left>
                    <AnimatedText text="Zion Christian Church" />
                  </Fade>
                  <Fade left>
                    <AnimatedText text="Isaiah 14" />
                  </Fade>
                  <Fade left>
                    <AnimatedParagraph text="<sup>32</sup> How then will one answer the messengers of the nation? That the LORD has founded Zion, And the afflicted of His people will seek refuge in it." />
                  </Fade>
                  <Space size={"large"} className="intro__button-group">
                    <Fade left>
                      <AnimatedButtons buttons={[
                        <AppButton type="primary">
                          <a
                              rel="noreferrer"
                              href="/about">
                            More
                          </a>
                        </AppButton>
                      ]} />
                    </Fade>
                    <Fade left>
                      <AnimatedButtons buttons={[
                        <AppButton>
                          <a href="/contact">
                            Contact US
                          </a>
                        </AppButton>
                      ]} />
                    </Fade>
                  </Space >
                </Col>
                <Col xs={24} md={12} className="home__img-wrapper">
                  <Fade right>
                    <AnimatedImage classNa={"home__img"} src={images.homeImage} alt="Home" />
                  </Fade>
                </Col>
              </Row>
            </Row>
            <br />
            <Divider style={{ backgroundColor: '#33965A', height: '4px' }} dashed={true} />
            <br />
            <Slide left>
              <VasheSamere />
            </Slide>
            <Divider style={{ backgroundColor: '#33965A', height: '4px' }} dashed={true} />
            <br />
            <Slide left>
              <BabaNehemiah />
            </Slide>
            <br />
            <Divider style={{ backgroundColor: '#33965A', height: '4px' }} dashed={true} />
            <br />
            <Row gutter={[24, 24]} justify="center">
                {ZCC.map((price, index) => (
                    <React.Fragment key={price.title}>
                      {index % 2 === 0 ? (
                          <>
                            <Col xs={24} sm={20} md={12} lg={12}>
                              <Fade left>
                                <PriceCard
                                    title={price.title}
                                    price={price.price}
                                    features={price.features}
                                />
                              </Fade>
                            </Col>
                            <Col xs={24} sm={20} md={12} lg={12}>
                              <Fade right>
                                <Image src={price.image} />
                              </Fade>
                            </Col>
                          </>
                      ) : (
                          <>
                            <Col xs={24} sm={20} md={12} lg={12}>
                              <Fade left>
                                <Image src={price.image} />
                              </Fade>
                            </Col>
                            <Col xs={24} sm={20} md={12} lg={12}>
                              <Fade right>
                                <PriceCard
                                    title={price.title}
                                    price={price.price}
                                    features={price.features}
                                />
                              </Fade>
                            </Col>
                          </>
                      )}
                    </React.Fragment>
                ))}
              </Row>
          </Container>
        </section>
    );
  }
}

// export default Home;

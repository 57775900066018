import {Timeline} from 'antd';
import {ClockCircleOutlined} from '@ant-design/icons'
import QueueAnim from 'rc-queue-anim';
export const SamuelMutendi = () =>(
    <QueueAnim delay={300} className="queue-simple">
        <div className="timeline_container">
                <Timeline mode={'left'}>
                    <Timeline.Item label="1913">The late Reverend Samuel Mutendi (born 1880) is baptised by the Holy Spirit at Chegutu/Hartley whilst serving as a policeman. In the same year is dismissed for his numerous Pentecostal seizures where he would speak in tongues. Goes back to his home area of Bikita where he stays at Mubveve Mission Station.</Timeline.Item>
                    <Timeline.Item label="1923">Under the guidance of the Holy Spirit Samuel Mutendi journeys to South Africa and receives water baptism from the Zion Apostolic Faith Mission (ZAFM) in Pretoria led by Rev. Mahlangu in which members spoke in tongues.
                        Samuel Mutendi returns to Southern Rhodesia/Zimbabwe as a ZAFM missionary and commissioned Reverend begins to preach the word of God baptising people and performing many faith miracles.</Timeline.Item>
                    <Timeline.Item label="1924">Goes back to South Africa at the invitation of his friend Enginas Lekganyane where they form the Zion Christian Church (ZCC) and submit registration papers in the Union Buildings in Pretoria.</Timeline.Item>
                    <Timeline.Item label="1924-1932">Rev. Mutendi preaches across Southern Rhodesia in his mission of fighting sickness and poverty . He uses his Holy Staff (maphumhangozi) and holy water (machipisa uroyi) to cast out evil spirits. Many are converted as he establishes his reputation as a faith healer, rainmaker, wise counsellor and bold man of God.
                        He and his converts endure persecution from local chiefs as well as from white missionaries who believe only they can preach and baptise people not an African missionary.</Timeline.Item>
                    <Timeline.Item label="1932-1976">Despite these odds Rev. Mutendi manages to spread the word of God across the country. During this period he manages to set up six schools in Masvingo, some which are banned and destroyed by colonial administrators.</Timeline.Item>
                    <Timeline.Item label="1948" dot={<ClockCircleOutlined className="timeline-clock-icon" />} color="red">Enginas Lekganyane dies and, with increased control of cross-border movement by the South Africa government, contact with South Africa is lost.</Timeline.Item>
                    <Timeline.Item label="1967">Due to his increased popularity the colonial powers finally move Rev. Samuel Mutendi and his followers to tsetse infested Gokwe (Marimasimbe) where he continues his mission and begins to develop the area by building schools as well as converting people.</Timeline.Item>
                    <Timeline.Item label="1972">The colonial powers further relocate him to Defe Dopota (Gokwe) at the fringes of Chirisa Game Park.</Timeline.Item>
                    <Timeline.Item label="1976" dot={<ClockCircleOutlined className="timeline-clock-icon" />}>Rev. Samuel Mutendi passes on (20 July) and God reveals his Glory by showing his portrait on a Star in the night sky (Nyeredzi yaSamere) on 23 July 1976. The incident is witnessed countrywide by both church members and non-members. MaZion each year commemorate the passing on of their founding father at Defe Dopota, an event known as 20 July/Zuva RaSamere .</Timeline.Item>
                </Timeline>
        </div>
    </QueueAnim>

)
import React, { useEffect, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { images } from "../../constants/imageData";
import "./Livestream.less";
import { Container } from '../../components/Container/Container';
import { SectionHeading } from '../../components/SectionHeading/SectionHeading';
import { Channel } from './Channel';
import Jump from "react-reveal/Jump";

export const Livestream = () => {
  // const [visible, setVisible] = useState(false);
  // const [videoUrl, setVideoUrl] = useState('');
  // const [hasLivestream, setHasLivestream] = useState(false);
  //
  // const fetchLiveBroadcasts = async () => {
  //   try {
  //     const apiKey = 'AIzaSyDYCQyJbR_vVLrbDJ-3wisXmadqfGKeM9A';
  //     const channelId = 'UCb6jT0K8n9S3KPL1pQu-OiQ';
  //
  //     const response = await fetch(
  //         `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&liveBroadcastContent=live&maxResults=1`
  //     );
  //     const data = await response.json();
  //
  //     console.log(data);
  //
  //     let videoId = null;
  //
  //     if (data.items && data.items.length > 0) {
  //       videoId = data.items[0].id.videoId;
  //     }
  //     setVideoUrl(videoId);
  //
  //     console.log("Video ID:", videoId);
  //
  //     if (data.items && data.items.length > 0) {
  //       setHasLivestream(true);
  //       const latestVideoId = data.items[0].id.videoId;
  //       showModal(latestVideoId);
  //     } else {
  //       setHasLivestream(false);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching live broadcasts:', error);
  //   }
  // };
  //
  // useEffect(() => {
  //   fetchLiveBroadcasts();
  //
  //   const interval = setInterval(fetchLiveBroadcasts, 6000000);
  //
  //   return () => clearInterval(interval);
  // }, []);
  //
  // const showModal = (url) => {
  //   // setVideoUrl(url);
  //   setVisible(true);
  // }
  //
  // const hideModal = () => {
  //   setVideoUrl('');
  //   setVisible(false);
  // }
  //
  // const renderVideoContent = () => {
  //   if (videoUrl) {
  //     return (
  //         // eslint-disable-next-line jsx-a11y/iframe-has-title
  //         <iframe
  //             width="711px"
  //             height="400px"
  //             src={`https://www.youtube.com/watch?v=${videoUrl}`}
  //             frameBorder="0"
  //             allowFullScreen
  //         ></iframe>
  //     );
  //   }
  //   return null;
  // }

  return (
      <section id="live" className="testimonial">
        <Channel  />
      </section>
  );
}

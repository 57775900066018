import React from 'react';
import {Card} from 'antd';
import "./AboutCard.less"

export const AboutCard = (props) => {
  const { Meta } = Card;
  const { title, description } = props;

  return (
    <Card
      hoverable
      className="about__card"
      style={{
        border: 'none',
        boxShadow: 'none',
      }}
    >
      <Meta title={title} description={description} style={{
        border: 'none',
        boxShadow: 'none',
      }} />
    </Card>
  )
}

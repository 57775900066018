// Post.js
import React from 'react';
import { Card } from 'antd';
import { CalendarOutlined, UserOutlined } from '@ant-design/icons';

import './Post.less';

export const Post = (props) => {
    const { Meta } = Card;

    const {
        title,
        description,
        timeRead,
        author,
        publishedTime,
        cover,
        alt,
        url,
        showCoverImage = false,
        showReadText = true, // New prop to control the display of "Read" text
    } = props;

    console.log('Props are', props);

    return (
        <a href={url}>
            <Card
                className="post"
                hoverable
                cover={showCoverImage ? <img alt={alt} src={cover} /> : null}
                actions={[
                    <div className="post__info">
                        <div>
                            <UserOutlined />
                            <span> Posted by {author}</span>
                        </div>
                        <div>
                            <CalendarOutlined />
                            <span> {publishedTime}</span>
                        </div>
                    </div>,
                ]}
            >
                <Meta title={title} description={description} />
                {showReadText && <time className="post__time-read">{timeRead} Read</time>}
            </Card>
        </a>
    );
};

import React, {useEffect} from 'react'
import {Col, Row} from 'antd';
import {Container} from '../../components/Container/Container';
import {AppButton} from '../../components/AppButton/AppButton';
import {BishopHeading} from './VasheHeading/BishopHeading';

import "./BabaNehemiah.less"

export const BabaNehemiah = () => {

  return (
      <div className="background-container">
              <Container className="business-plan__container">
                  <Row gutter={64}>
                      <Col sm={24} md={24} style={{ textAlign: 'center' }}>
                          <BishopHeading heading="Bishop Dr. Nehemiah Mutendi" subHeading="Matthew 28 vs 19" className={'nehemiah'} />

                          <p style={{ color: 'white', fontSize: '1.2rem' }} className="business-plan__desc">
                              Go ye therefore, and teach all nations, baptizing them in the name of the Father, and of the Son, and of the Holy Ghost: Teaching them to observe all.
                          </p>
                          <a
                              href="/about"
                              rel="noreferrer"
                          >
                              <AppButton type="primary" >
                                  Know More
                              </AppButton>
                          </a>
                      </Col>
                  </Row>
              </Container>
      </div>
  )
}

import React, { useState, useEffect } from 'react';
import { Affix, Menu } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import Layout, { Header } from 'antd/lib/layout/layout';
import {
  BookOutlined,
  ContactsOutlined,
  HistoryOutlined,
  HomeOutlined,
  MenuOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  YoutubeOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import './AppHeader.less';
import { images } from '../../constants/imageData';
import Flag from 'react-world-flags';
import { Container } from "../Container/Container";
import { worldCountries } from "./worldCountries";
const baseapiurl = process.env.REACT_APP_BASE_API_URL;
export const AppHeader = () => {
  const { Item } = Menu;
  const location = useLocation();
  const selectedKey = location.pathname.slice(1) || 'home';

  const [countryCodes, setCountryCodes] = useState([]);
  const [animationIndex, setAnimationIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedKey]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("accept", "application/hal+json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${baseapiurl}contacts/all?page=0&size=20`, requestOptions)
        .then(response => response.json())
        .then(data => {
          const contacts = data.content;
          const countryDictionary = {};

          contacts.forEach(contact => {
            const country = contact.country;

            if (worldCountries[country]) {
              countryDictionary[country] = worldCountries[country];
            }
          });

          const uniqueCountryCodes = Object.values(countryDictionary);

          setCountryCodes(uniqueCountryCodes);

          startAnimation();
        })
        .catch(error => console.error('API call failed:', error));
  }, []);

  const startAnimation = () => {
    if (countryCodes.length > 0) {
      // Rotate the flags every 5 seconds
      const interval = setInterval(() => {
        setAnimationIndex(prevIndex => (prevIndex + 1) % countryCodes.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  };

  return (
      <div style={{ background: 'white' }}>
        <Affix>
          <Layout>
            <Header className="app-header-flags" style={{ float: 'right' }}>
              <div className={`marquee-flags-container`} style={{ marginRight: '20px', marginLeft: '20px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {countryCodes.map((code, index) => (
                    <React.Fragment key={code}>
                      <a href={"/contact"}>
                        <Flag code={countryCodes[(animationIndex + index) % countryCodes.length]} height={15} className="marquee-flag" style={{ transition: 'transform 0.5s' }} />
                        {index !== countryCodes.length - 1 && <span style={{ marginRight: '10px' }}></span>}
                      </a>
                    </React.Fragment>
                ))}
              </div>
            </Header>
          </Layout>
        </Affix>
        <Affix offsetTop={0}>
          <Header className="app-header">
            <Container className="app-header__content">
              <div app-header__logo-background>
                <a href="/" style={{ float: 'left', marginRight: '10px' }}>
                  <img
                      className="app-header__logo app-header__logo-background"
                      src={images.LOGO}
                      alt="logo"
                  />
                </a>
              </div>

              <Menu
                  className="app-header__menu"
                  mode={"horizontal"}
                  selectedKeys={[selectedKey]}
                  overflowedIndicator={<MenuOutlined className="app-header__menu-icon" />}
                  style={{ float: 'right', width: '90%' }}
              >
                <Item key="home" icon={<HomeOutlined />}>
                  <Link to="/" className="app-header__menu-item">
                    Home
                  </Link>
                </Item>
                <Item key="about" icon={<BookOutlined />}>
                  <Link to="/about" className="app-header__menu-item">
                    About
                  </Link>
                </Item>
                <Item key="beliefs" icon={<OrderedListOutlined />}>
                  <Link to="/beliefs" className="app-header__menu-item">
                    Beliefs
                  </Link>
                </Item>
                <Item key="history" icon={<HistoryOutlined />}>
                  <Link to="/history" className="app-header__menu-item">
                    History
                  </Link>
                </Item>
                <Item key="news" icon={<NotificationOutlined />}>
                  <Link to="/news" className="app-header__menu-item">
                    News
                  </Link>
                </Item>
                <Item key="livestream" icon={<YoutubeOutlined />}>
                  <Link to="/livestream" className="app-header__menu-item">
                    Watch Live
                  </Link>
                </Item>
                <Item key="contact" icon={<ContactsOutlined />}>
                  <Link to="/contact" className="app-header__menu-item">
                    Contact
                  </Link>
                </Item>
                <Item key="events" icon={<CalendarOutlined />}>
                  <Link to="/events" className="app-header__menu-item">
                    Events
                  </Link>
                </Item>
                {/*<Item key="media" icon={<PictureOutlined />}>*/}
                {/*  <Link to="/media" className="app-header__menu-item">*/}
                {/*    Media*/}
                {/*  </Link>*/}
                {/*</Item>*/}
              </Menu>
            </Container>
          </Header>
        </Affix>
      </div>
  );
};

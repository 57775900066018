import React, {useState} from 'react';
import {Card, Divider} from 'antd';
import {SectionHeading} from '../../components/SectionHeading/SectionHeading';
import {Milestones} from './elements/Milestones';
import {SamuelMutendi} from './elements/SamuelMutendi';
import {NehemiahMutendi} from './elements/NehemiahMutendi';
import {Container} from '../../components/Container/Container';

import './History.less'
import Jump from "react-reveal/Jump";

const tabList = [
  {
    key: 'tab1',
    tab: <p className='tab-heading'>Right Reverend Samuel Mutendi</p>,
  },
  {
    key: 'tab2',
    tab: <p className='tab-heading'>Bishop Samuel Mutendi</p>,
  },
  {
    key: 'tab3',
    tab: <p className='tab-heading'>Milestones</p>,
  },
];
const contentList = {
  tab1: <SamuelMutendi />,
  tab2: <NehemiahMutendi />,
  tab3: <Milestones />,
};

export const History = () =>{ 
  
  const [activeTabKey1, setActiveTabKey1] = useState('tab1');
  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };
  return (
    <section id='history' className="history">
      <Container>
        <Jump>
          <SectionHeading heading="History" subHeading="Zion Christian Church (ZCC) is one of the oldest independent African churches in Zimbabwe." />
        </Jump>
        <Divider style={{backgroundColor: '#33965A',  height: '4px'}} dashed={true} />
        <Card
        className='history__content'
          style={{
            width: '100%',
          }}
          tabList={tabList}
          activeTabKey={activeTabKey1}
          onTabChange={onTab1Change}
        >
          {contentList[activeTabKey1]}
        </Card>
        <br />

    </Container>
    </section>
)};

import React from 'react';
import {Container} from '../../components/Container/Container';
import "./Contact.less";
import {City} from "./City/City";

export const Contact = () => {

  return (
    <section id="contact" className="contact">
      <Container>
          <City />
      </Container>
    </section >
  )
}

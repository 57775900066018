import {Route, Routes} from 'react-router-dom';


import Layout, {Content} from 'antd/lib/layout/layout';

import {AppHeader} from './components/AppHeader/AppHeader';
import {Home} from './containers/Home/Home';
import {About} from './containers/About/About';
// import {Media} from './containers/Media/Media';
import {Beliefs} from './containers/Beliefs/Beliefs';
import {Livestream} from './containers/Livestream/Livestream';
import {News} from './containers/Blog/News';
import {Contact} from './containers/Contact/Contact';
import {AppFooter} from './components/AppFooter/AppFooter';
import {History} from './containers/History/History'

import './App.less';
import Article from "./containers/Blog/Article/Article";
import {Media} from "./containers/Media/Media";
import EventsPage from "./containers/Events/EventsPage";

function App() {
  return (
    <Layout>
      <AppHeader />
        <Content>
          <Routes>
            <Route exact path="" element={ <Home /> } />
            <Route exact path="/about" element={ <About /> } />
            <Route exact path="/beliefs" element={ <Beliefs /> } />
            <Route exact path="/history" element={ <History /> } />
            <Route exact path="/news" element={ <News /> } />
            <Route exact path="/livestream" element={ <Livestream /> } />
            <Route exact path="/contact" element={ <Contact /> } />
            <Route path="/article/:slug" element={<Article />} />
            <Route exact path="/media" element={ <Media title="Media" /> } />
            <Route exact path="/events" element={ <EventsPage /> } />
          </Routes>
        </Content>
     <AppFooter />
    </Layout>
  );
}

export default App;

import {ClockCircleOutlined} from '@ant-design/icons'
import {Timeline} from 'antd';

export const NehemiahMutendi = () =>(
    <div className="timeline_container">
  <Timeline mode={'left'}>
    <br />
    <Timeline.Item label="1978">Bishop Nehemiah Mutendi (born 1939) is ordained and continues with the church's founding principles of fighting sickness and poverty.</Timeline.Item>
    <Timeline.Item label="1981">Z.C.C. purchases Mbungo Estates in Masvingo, its current administrative headquarters.</Timeline.Item>
    <Timeline.Item label="1981-2004" >Z.C.C. builds 11 schools countrywide. To date the schools have produced over 5000 graduates.</Timeline.Item>
    <Timeline.Item label="2006-2011">The construction of the House of God at Mbungo.</Timeline.Item>
    <Timeline.Item label="2011">Bishop Dr. Nehemiah Mutendi is awarded an honorary doctorate degree [Dr. Litt] by the University of Zimbabwe in recognition of the churchâ€™s contribution to education and social empowerment.</Timeline.Item>
    <Timeline.Item label="2012">Bishop Dr. Nehemiah embarks on a journey to Israel (The Holy Land) with 56 church members. This is now an annual event.</Timeline.Item>
    <Timeline.Item label="2013" dot={
        <ClockCircleOutlined
          style={{
            fontSize: '16px',
          }}
        />
      }>ZCC celebrates its Centenary and commissions a Second Century of Christian work.</Timeline.Item>
  </Timeline>
</div>
)
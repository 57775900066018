import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { Center } from './Center/Center';
import { Container } from '../../../components/Container/Container';
import { SectionHeading } from '../../../components/SectionHeading/SectionHeading';
import { images } from "../../../constants/imageData";

import "./City.less"
const baseapiurl = process.env.REACT_APP_BASE_API_URL;
export const City = () => {
    const [CentersList, setCentersList] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({
        country: '',
        city: '',
        center: '',
        phone: ''
    });

    useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("accept", "application/hal+json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${baseapiurl}contacts/all?page=0&size=20`, requestOptions)
        .then(response => response.json())
        .then(data => {
          const contacts = data.content;
          const groupedCenters = {};

          contacts.forEach(contact => {
            const key = `${contact.country}-${contact.city}`;
            if (!groupedCenters[key]) {
              groupedCenters[key] = {
                country: contact.country,
                city: contact.city,
                centers: [],
              };
            }

              const imgSrc = contact.imageDownloadUrl !== null
                  ? `https://zccmbungo.org/zcc-website-api/api${contact.imageDownloadUrl}`
                  : images.location;

            groupedCenters[key].centers.push({
              name: contact.name,
              position: contact.address,
              img: imgSrc,
              phone: contact.phone,
              longitude: contact.longitude,
              latitude: contact.latitude,
            });
          });


          const updatedCentersList = Object.values(groupedCenters);
          setCentersList(updatedCentersList);

        })
        .catch(error => console.log('error', error));
  }, []);

    const filteredCenters = CentersList.filter(group => {
        return (
            group.country.toLowerCase().includes(filterCriteria.country.toLowerCase()) &&
            group.city.toLowerCase().includes(filterCriteria.city.toLowerCase()) &&
            group.centers.some(center =>
                center.name.toLowerCase().includes(filterCriteria.center.toLowerCase()) ||
                center.phone.includes(filterCriteria.phone)
            )
        );
    });

    return (
        <section id="team" className="team">
            <Container>
                <div className="filter-fields">
                    <div className="filter-input">
                        <label htmlFor="country">Country:</label>
                        <input
                            type="text"
                            id="country"
                            placeholder="Filter by Country"
                            value={filterCriteria.country}
                            onChange={e => setFilterCriteria({ ...filterCriteria, country: e.target.value })}
                        />
                    </div>
                    <div className="filter-input">
                        <label htmlFor="city">City:</label>
                        <input
                            type="text"
                            id="city"
                            placeholder="Filter by City"
                            value={filterCriteria.city}
                            onChange={e => setFilterCriteria({ ...filterCriteria, city: e.target.value })}
                        />
                    </div>
                </div>


                {filteredCenters.map(group => (
                    <div key={`${group.country}-${group.city}`}>
                        <SectionHeading heading={group.country} subHeading={group.city} />
                        <Row gutter={[24, 24]} justify="center">
                            {group.centers.map(center => (
                                <Col xs={20} sm={12} md={8} lg={6} key={center.name + center.position}>
                                    <Center
                                        name={center.name}
                                        position={center.position}
                                        img={center.img}
                                        phone={center.phone}
                                        longitude={center.longitude}
                                        latitude={center.latitude}
                                    />
                                </Col>
                            ))}
                        </Row>
                        <br />
                        <br />
                    </div>
                ))}
            </Container>
        </section>
    )
}
import React from 'react';
import {Card} from 'antd';

import "./PriceCard.less"

export const PriceCard = (props) => {
  const { title, price, features } = props;

  return (
    <Card className="price-card" hoverable>
      <h4 className="price-card__title">{title}</h4>
      <p className="price-card__price">
        {price}
      </p>
      {
        features.map(feature => (
          <p className="price-card__feature" key={title + feature}>{feature}</p>
        ))
      }
    </Card >
  )
}
